<template>
  <main>
    <StoryblokComponent v-if="blok.hero?.length === 1" :blok="blok.hero[0]" />
    <div
      v-for="section in blok.body"
      :key="section._uid"
      :class="`background background-${section.component}`"
    >
      <StoryblokComponent
        :blok="section"
        :class="isSectionClass(section.component)"
        tag="section"
      />
    </div>
  </main>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
    default: () => {
      return {};
    },
  },
});

useHead({
  bodyAttrs: {
    class: "header-white header-absolute",
  },
});
</script>

<style lang="scss" scoped>
.background-domusvi-team {
  @include pair-1;
}
</style>
